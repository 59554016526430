import React from "react"
// import MultiOntologiesMenu from '../../components/dashorm/menuHelpers/MultiOntologiesMenu.js';

// local test url : http://localhost:1111/devTests/MultiOntologyTest/

export default function MultiOntologyTest() {
  return (
    <div>
      <p>@TODO: result of "big migration", restore or delete it</p>
      {/* <h1>Tree to test : </h1>
      <MultiOntologiesMenu /> */}
    </div>
  )
}
